










import Vue from 'vue';
import Component from 'vue-class-component';
import { Stream } from '@/models/data/models';

@Component({})
export default class DataStreamList extends Vue {
  Stream = Stream;

  get filter() {
    return {
      application: this.$store.getters['global/application']
        ? this.$store.getters['global/application'].id
        : 'unknown', // explicitly set to 'unknown' to not show anything
    };
  }
}
